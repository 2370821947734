header.buycex-master__header {
  background: #17181e !important;
  transition: all ease-out 300ms;
  /* Header => SideBar => User => Not Verified User */
  /* Header => SideBar => User => VIP Level Info */
}
header.buycex-master__header a {
  font-size: 14px;
  transition: all ease-out 300ms;
}
header.buycex-master__header span {
  color: #FFFFFF;
}
header.buycex-master__header a:hover {
  text-decoration: none !important;
}
header.buycex-master__header div.buycex-header__nav__search-bar {
  background: #FFFFFF !important;
  border-radius: 0.375rem;
  transition: all ease-out 300ms;
}
header.buycex-master__header div.buycex-header__nav__search-bar input {
  background: #FFFFFF !important;
  transition: all cubic-bezier(0.175, 0.885, 0.32, 1.275) 300ms;
  width: 150px !important;
  border: none !important;
}
header.buycex-master__header div.buycex-header__nav__search-bar input:focus {
  width: 200px !important;
  border: 0.5px solid #EFB18C !important;
  border-radius: 0.375rem;
  margin-left: 8px;
  padding-left: 14px !important;
}
header.buycex-master__header div.buycex-header__nav__search-bar:focus-within {
  background: #EFB81C !important;
}
header.buycex-master__header div.header__user-side-menu {
  box-shadow: rgba(136, 165, 191, 0.38) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
}
header.buycex-master__header div.header__user-side-menu div.user-side-menu__user-status-wrapper span.user-status-wrapper__username {
  font-size: 18px !important;
}
header.buycex-master__header #verification-status {
  color: #FFFFFF !important;
  font-size: 10px !important;
  background-color: #17181e !important;
  opacity: 100% !important;
  z-index: 99;
}
header.buycex-master__header #vip-level-info {
  color: #151415 !important;
  background-color: #17181e !important;
  opacity: 100% !important;
  z-index: 99;
  max-width: 120px;
}
header.buycex-master__header ul.user-side-menu__menu-list button.menu-list__logout-btn:hover * {
  color: #BB2124 !important;
}

header.buycex-master__header.header-sticky {
  background: #17181e !important;
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
  position: sticky;
  top: 0;
}
header.buycex-master__header.header-sticky div.buycex-header__nav__search-bar {
  background: #F4F4F4 !important;
}
header.buycex-master__header.header-sticky div.buycex-header__nav__search-bar input {
  background: #F4F4F4 !important;
}
header.buycex-master__header.header-sticky div.buycex-header__nav__search-bar input:focus {
  background: #FFFFFF !important;
}
header.buycex-master__header.header-sticky div.buycex-header__nav__search-bar:focus-within {
  background: #EFB81C !important;
}

.shadow-menu {
  box-shadow: rgba(0, 0, 0, 0.4) 6px 2px 2px 0px, rgba(0, 0, 0, 0.2) -6px -2px 2px 0px;
}

@media screen and (min-width: 1400px) {
  header.buycex-master__header > div {
    max-width: 1900px;
    width: 100%;
    margin: auto;
  }
  div.header__user-side-menu {
    height: -moz-max-content !important;
    height: max-content !important;
    overflow: hidden;
  }
}
/* Scrollbar styling */
.custom-scrollbar {
  scrollbar-width: thin; /* for Firefox */
  scrollbar-color: #a0a0a0 transparent; /* Thumb visible, track hidden */
}

/* For WebKit browsers */
.custom-scrollbar::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent; /* Hide the scrollbar track */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #a0a0a0; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners */
  border: 2px solid transparent; /* Space around thumb to hide track */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #f5f5f5; /* Darker color on hover */
}

/* Hide the scrollbar arrow buttons */
.custom-scrollbar::-webkit-scrollbar-button {
  display: none !important; /* Hide up/down or left/right arrow buttons */
}
