/* Styles for login page and it's responsiveness */
@media screen and (min-width: 1920px) {
    div.login-container {
      width: 800px;
    }
    div.login-container div.login__form__wrapper div.form-title {
      margin-bottom: 1.5rem;
    }
    div.login-container div.login__form__wrapper form input {
      height: 50px;
    }
  }
  @media screen and (min-width: 1440px) and (max-width: 1919px) {
    div.login-container {
      width: 750px;
    }
  }
  @media screen and (min-width: 1280px) and (max-width: 1439px) {
    div.login-container {
      width: 750px;
      margin-top: 12% !important;
    }
    div.login-container div.login__form__wrapper {
      padding: 0px 20px !important;
    }
    div.login-container div.login__form__wrapper div.form-title {
      gap: 0.25rem !important;
    }
    div.login-container div.login__form__wrapper div.form-title h1 {
      font-size: 1.5em;
      width: -moz-max-content;
      width: max-content;
    }
    div.login-container div.login__form__wrapper div.form-title p {
      font-size: 12px;
      width: -moz-max-content;
      width: max-content;
    }
    div.login-container div.login__form__wrapper div.form-tabs {
      margin-bottom: 1rem !important;
      gap: 1rem;
    }
    div.login-container div.login__form__wrapper div.form-tabs button {
      font-size: 12px;
    }
    div.login-container div.login__form__wrapper div.form-field-wrapper form input {
      height: 45px;
      font-size: 14px;
    }
    div.login-container div.login__form__wrapper div.form-field-wrapper form input::-moz-placeholder {
      font-size: 12px;
    }
    div.login-container div.login__form__wrapper div.form-field-wrapper form input::placeholder {
      font-size: 12px;
    }
    div.login-container div.login__form__wrapper div.form-field-wrapper form button.password-toggle-btn {
      right: 5px;
      bottom: 12px;
    }
    div.login-container div.login__form__wrapper div.form-field-wrapper form div.form-field-wrapper__forget-password a {
      font-size: 12px;
    }
    div.login-container div.login__form__wrapper div.form-field-wrapper form button.login-button {
      margin-top: 1rem;
      padding: 0.8rem;
      font-size: 14px;
      font-weight: 500;
    }
    div.login-container div.login__form__wrapper div.login-form__footer div.login-form__footer__continue span {
      font-size: 12px !important;
    }
    div.login-container div.login__form__wrapper div.login-form__footer button.footer__google-btn {
      font-size: 14px;
      padding: 0.8rem;
      font-weight: 500;
    }
    div.login-container div.login__form__wrapper div.login-form__footer button.footer__google-btn svg {
      width: 15px !important;
      height: 15px !important;
    }
    div.login-container div.login__form__wrapper div.login-form__footer div.footer__sign-up-redirect a,
    div.login-container div.login__form__wrapper div.login-form__footer div.footer__sign-up-redirect span {
      font-size: 12px;
    }
  }
  @media screen and (min-width: 1024px) and (max-width: 1279px) {
    div.login-container {
      width: 650px;
      margin-top: 12% !important;
    }
    div.login-container div.login__form__wrapper {
      padding: 0px 20px !important;
    }
    div.login-container div.login__form__wrapper div.form-title {
      gap: 0.25rem !important;
    }
    div.login-container div.login__form__wrapper div.form-title h1 {
      font-size: 1.25em;
      width: -moz-max-content;
      width: max-content;
    }
    div.login-container div.login__form__wrapper div.form-title p {
      font-size: 12px;
      width: -moz-max-content;
      width: max-content;
    }
    div.login-container div.login__form__wrapper div.form-tabs {
      margin-bottom: 1rem !important;
      gap: 1rem;
    }
    div.login-container div.login__form__wrapper div.form-tabs button {
      font-size: 12px;
    }
    div.login-container div.login__form__wrapper div.form-field-wrapper form input {
      height: 40px;
      font-size: 14px;
    }
    div.login-container div.login__form__wrapper div.form-field-wrapper form input::-moz-placeholder {
      font-size: 12px;
    }
    div.login-container div.login__form__wrapper div.form-field-wrapper form input::placeholder {
      font-size: 12px;
    }
    div.login-container div.login__form__wrapper div.form-field-wrapper form div.form-field-wrapper__forget-password a {
      font-size: 12px;
    }
    div.login-container div.login__form__wrapper div.form-field-wrapper form button.login-button {
      margin-top: 1rem;
      padding: 0.5rem;
      font-size: 14px;
    }
    div.login-container div.login__form__wrapper div.login-form__footer div.login-form__footer__continue span {
      font-size: 12px !important;
    }
    div.login-container div.login__form__wrapper div.login-form__footer button.footer__google-btn {
      font-size: 12px;
      padding: 0.5rem;
      font-weight: 500;
    }
    div.login-container div.login__form__wrapper div.login-form__footer button.footer__google-btn svg {
      width: 15px !important;
      height: 15px !important;
    }
    div.login-container div.login__form__wrapper div.login-form__footer div.footer__sign-up-redirect a,
    div.login-container div.login__form__wrapper div.login-form__footer div.footer__sign-up-redirect span {
      font-size: 12px;
    }
  }
  @media screen and (min-width: 991px) and (max-width: 1280px) {
    div.login-container {
      width: 800px;
    }
  }
  @media screen and (min-width: 800px) and (max-width: 1280px) {
    div.login-container {
      width: 750px;
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    div.login-container {
      width: 600px;
    }
  }
  @media screen and (min-width: 640px) and (max-width: 767px) {
    div.login-container {
      width: 100%;
      box-shadow: unset !important;
    }
    div.login-container div.login__form__wrapper {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
  @media screen and (max-width: 639px) {
    div.login-container {
      width: 100%;
      box-shadow: unset !important;
    }
    div.login-container div.login__form__wrapper {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
  div.login-container {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 15px;
  }
  div.login-container input:hover,
  div.login-container input:not([value=""]),
  div.login-container input:active {
    border-color: #EFB81C !important;
  }
  
  .react-tel-input .form-control {
    padding-left: 60px !important;
    width: 100%;
    background: unset !important;
    border-radius: 0.375rem !important;
    height: unset !important;
    width: 100% !important;
    outline: none !important;
  }
  
  .react-tel-input .country-list {
    width: 318px !important;
  }
  
  .react-tel-input .flag-dropdown {
    padding: 0px 5px !important;
    background: transparent !important;
  }
  
  .react-tel-input .country-list {
    left: 0;
    top: 48px;
  }/*# sourceMappingURL=Login.css.map */