div.header__user-side-menu {
    &.shadow-menu {
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }

    div.user-side-menu__user-status-wrapper {
        span.user-status-wrapper__username {
            font-size: 18px !important;
        }
    }

    div.user-side-menu__menu-wrapper {
        ul.user-side-menu__menu-list {
            li.menu-list__item {

                *,
                *:hover {
                    transition: none !important;
                }

                &:hover {
                    transition: none !important;

                    span.menu-item__list-icon {
                        transition: none !important;
                        color: #151415 !important;
                    }
                }

                button.menu-list__logout-btn:hover {
                    transition: all 2s ease-in-out !important;

                    span {
                        animation: bounceRight 1000ms 1 cubic-bezier(0.97, 0.03, 0.03, 0.97) both !important;
                    }
                }

            }
        }
    }
}

@keyframes bounceRight {
    0% {
        transform: translateX(0);
    }

    50% {
        transform: translateX(15px);
    }

    100% {
        transform: translateX(0);
        color: #BB2124;
    }
}