section.hero-section {
    position: relative;
    background-image:
        linear-gradient(to bottom, #FFFFFF, #F4F4F410, #EFB81C10),
        url("../../../assets/img/global/buycex-card-bg.svg");
    background-repeat: no-repeat;
    background-size: 160% 70%;
    background-position: bottom;
    max-height: 60vh;

    div.hero-section__wrapper {


        .hero-description {
            position: relative;
            overflow: hidden;
            font-weight: 400;

            span {
                font-size: 24px !important;
            }
        }

        .flip-text {
            display: inline-block;
            position: relative;
            opacity: 0;
            transform: translateY(100%);
            transition: all 0.6s cubic-bezier(0.785, 0.135, 0.15, 0.86);
            color: #EFB81C;
            font-weight: 500;
        }

        .flip-enter {
            opacity: 1;
            transform: translateY(0%);
        }

        .flip-exit {
            opacity: 0;
            transform: translateY(-100%);
        }



        .hero-image {
            opacity: 0;
            transition: opacity 0.7s ease-in-out;
        }

        .hero-image.active {
            opacity: 1;
        }

        .hero-image.inactive {
            opacity: 0;
        }

        .fade-in {
            opacity: 1;
        }

        .fade-out {
            opacity: 0;
        }
    }
}


@media screen and (max-width: 911px) {
    section.hero-section {
        max-height: unset;
        height: max-content;

        div.hero-section__wrapper {
            max-height: unset;
            height: max-content;
            flex-direction: column;
            padding: 5rem 1rem;

            div.hero-title-wrapper {
                width: 100%;

                h1.hero-title {
                    span {
                        font-size: 32px;
                    }
                }

                p.hero-description {
                    span {
                        font-size: 1.5em !important;
                    }
                }
            }

            div.hero-image-wrapper {
                width: 100%;
                min-height: unset;
                max-height: unset;
                height: 200px;
                display: none;
            }
        }
    }
}


@media screen and (min-width: 1920px) {
    section.hero-section {
        // min-height: 75vh;
    }
}

section.hero-section-black{
    position: relative;
    background-repeat: no-repeat;
    // background-size: 160% 70%;
    background-position: bottom;
    background-color: black;
    height: 280px;
    div.hero-section__wrapper-black {


        .hero-description-black {
            position: relative;
            overflow: hidden;
            font-weight: 400;

            span {
                font-size: 14px !important;
            }
        }

        .flip-text-black {
            display: inline-block;
            position: relative;
            opacity: 0;
            transform: translateY(100%);
            transition: all 0.6s cubic-bezier(0.785, 0.135, 0.15, 0.86);
            color: #EFB81C;
            font-weight: 500;
        }

        .flip-enter {
            opacity: 1;
            transform: translateY(0%);
        }

        .flip-exit {
            opacity: 0;
            transform: translateY(-100%);
        }



        .hero-image-black {
            opacity: 0;
            transition: opacity 0.7s ease-in-out;
            height: 10rem;
        }

        .hero-image-black.active {
            opacity: 1;
        }

        .hero-image-black.inactive {
            opacity: 0;
        }

        .fade-in {
            opacity: 1;
        }

        .fade-out {
            opacity: 0;
        }
    }
}
div.hero-title-wrapper-black {
    width: 100%;

    h1.hero-title-black {
        span {
            font-size: 32px !important;
            font-weight: 600;
        }
    }
}

@media screen and (max-width: 640px) {  
    section.hero-section-black{ 
        min-height: 380px;
        height: auto !important;
    }
}

