section.download-apps-section {
    position: relative;
    background-image:
        linear-gradient(to bottom, #EFB81C10, #F4F4F410, #EFB81C10),
        url("../../../assets/img/home-page/hero-section/hero-background-svg.svg");
    background-repeat: no-repeat;
    background-size: 160% 100%;
    background-position: bottom;
}

.image-fade-wrap {
    position: relative;
    width: 100%;
    height: auto;
    overflow: hidden;
}

.fade-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    opacity: 0;
    transition: opacity 2s ease-in-out;
}

.fade-up {
    opacity: 1;
}

.fade-down {
    opacity: 0;
}


@media screen and (max-width: 991px) {
    section.download-apps-section {
        div.container {
            div.wrapper-container {
                flex-direction: column;
                gap: unset;

                div.title-content-wrap {
                    width: 100%;

                    div.title-wrap {
                        h3 {
                            font-size: 22px;
                        }

                        p {
                            font-size: 14px;
                        }
                    }


                    div.download-btn-wrap {
                        div.qr-scanner {
                            width: 100%;
                            gap: 5px;

                            p {
                                font-size: 14px;
                            }
                        }

                        div.app-buttons {
                            width: 100%;
                            flex-wrap: wrap;
                            justify-content: space-between;
                            column-gap: 5px;

                            button:not(:last-child) {
                                width: 46%;
                            }
                        }
                    }
                }



                div.app-ui-slider-wrapper {
                    width: 100%;
                    display: none;

                    div.slider-wrapper {
                        height: 222px;
                    }
                }
            }
        }
    }
}