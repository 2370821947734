 .earn-overview .items{
    
    width: 32px;
    height: 32px;
    border: 1px solid #d5dae0;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #121214;
    
    cursor: pointer;
 }
 .earn-overview .active{
    background: #EFB81C;
    border-color: #EFB81C;
 }
 .earn-overview-header{
   position: relative;
   background-image: linear-gradient(to bottom, #FFFFFF, rgba(244, 244, 244, 0.062745098), rgba(239, 184, 28, 0.062745098)), url("assets/img/global/buycex-card-bg.svg");
   background-repeat: no-repeat;
   background-size: 160% 100%;
   background-position: bottom;  
 } 


 .stake-model{
   .ant-modal-content{
      padding: 0px!important;
   }
   .ant-modal-footer{
         display: none;
   }
 }



 /* stake now slider  */
 /* From Uiverse.io by Muaz-Aldalil */ 
.toggle-checkbox {
   -webkit-appearance: none;
   -moz-appearance: none;
   appearance: none;
   display: none;
 }
 
 .toggle-switch {
   background-color: gray;
   width: 50px;
   height: 20px;
   border-radius: 16px;
   position: relative;
   transition: 0.3s;
   cursor: pointer;
 }
 
 .toggle-switch::before {
   content: "";
   font-weight: 900;
   background-color: white;
   width: 15px;
   height: 15px;
   position: absolute;
   border-radius: 50%;
   top: 3px;
   left: 4px;
   display: flex;
   justify-content: center;
   align-items: center;
   color: #ddd;
   transition: 0.3s;
 }
 
 .toggle-checkbox:checked+.toggle-switch {
   background-color: #0075ff;
 }
 
 .toggle-checkbox:checked+.toggle-switch::before {
   content: "";
   left: 31px;
   color: #0075ff;
 }