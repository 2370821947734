//  spot-trade/scss/OrderBook.scss

.order-book__header__table-header {
    span {
        width: calc(100% / 3);
        text-align: left;
    }
}


.orders-table,
.trades-table {
    tbody {
        td {
            width: calc(100% / 3);
            text-align: left;
        }
    }
}