
    
 .ant-drawer-content-wrapper{
    width: 1100px!important;
}

.profit-model .ant-btn{
    display: none!important;
}
.dark .ant-drawer-content{
  
    background-color: #101014;
    
}