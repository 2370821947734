@media screen and (max-width: 991px) {
  div.chat-support-btn {
    right: 15px;
    bottom: 15px;
  }
  div.chat-field-wrapper {
    width: 345px;
    height: 90vh;
    bottom: 15px;
    right: 15px;
  }
  div.chat-field-wrapper div.chat-field__header {
    padding: 10px;
  }
  div.chat-field-wrapper div.chat-field__header div.chat-field__header__title-wrap div.chat-field__title {
    font-size: 13px;
  }
  div.chat-field-wrapper div.chat-field__header div.chat-field__header__title-wrap p {
    font-size: 12px;
  }
  div.quick-questions-wrapper div.quick-questions__item div.quick-questions__item-header .quick-questions__item__header__title {
    font-size: 14px;
  }
  div.quick-questions-wrapper div.quick-questions__item div.quick-questions__item-header a * {
    font-size: 14px;
  }
  div.quick-questions-wrapper div.quick-questions__item div.quick-questions__item-body div.quick-questions__item__body-item ul.quick-questions__item__body-item__list li a * {
    font-size: 12px;
  }
  div.quick-questions-wrapper div.quick-questions__other-item div.quick-questions__other-item__header .quick-questions__other-item__header-title {
    font-size: 14px;
  }
  div.quick-questions-wrapper div.quick-questions__other-item div.quick-questions__other-item__header a * {
    font-size: 14px;
  }
  div.quick-questions-wrapper div.quick-questions__faqs div.quick-questions__faqs-header .quick-questions__faqs-header__title {
    font-size: 14px;
  }
  div.quick-questions-wrapper div.quick-questions__faqs div.quick-questions__faqs-header a * {
    font-size: 14px;
  }
  div.quick-questions-wrapper div.quick-questions__faqs div.quick-questions__faqs-body ul li * {
    font-size: 12px;
  }
  div.quick-questions-wrapper div.quick-questions__faqs div.quick-questions__faqs-body ul li * .quick-questions__faqs__item-title {
    font-size: 13px !important;
  }
}/*# sourceMappingURL=ChatBotStyle.css.map */