.shadow-card {
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
}


@media screen and (max-width: 911px) {
    section.market-top-coins-section {
        div.container {
            div.market-top-coins-header {
                h2 {
                    font-size: 22px;
                }

                button {
                    font-size: 12px;
                }
            }

            div.market-top-coins-wrapper {
                flex-direction: column;

                div.market-top-category {
                    .category-wrapper {}
                }
            }
        }
    }
}