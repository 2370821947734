/* Styles for SignUp page and it's responsiveness */
@media screen and (min-width: 1920px) {
    div.register-container {
        width: 950px;

        &>div {
            align-items: center;
        }

        div.register__form__wrapper {

            div.form-title {
                margin-bottom: 1.5rem;
            }

            form {
                input {
                    height: 50px;
                }
            }
        }

    }
}

@media screen and (min-width: 1440px) and (max-width: 1919px) {
    div.register-container {
        width: 800px;
        margin-top: 5% !important;

        &>div {
            align-items: center;
        }

        div.register__form__wrapper {
            padding: 0px 20px !important;

            div.form-title {
                gap: 0.25rem !important;

                h1 {
                    font-size: 1.25em;
                    width: max-content;
                }

                p {
                    font-size: 12px;
                    width: max-content;
                }
            }

            div.form-tabs {
                margin-bottom: 1rem !important;
                gap: 1rem;

                button {
                    font-size: 12px;
                }
            }

            div.form-field-wrapper {
                form {
                    input {
                        height: 45px;
                        font-size: 14px;

                        &::placeholder {
                            font-size: 12px;
                        }
                    }

                    button.password-toggle-btn {
                        right: 5px;
                        bottom: 12px;
                    }

                    div.form-field-wrapper__forget-password {
                        a {
                            font-size: 12px;
                        }
                    }

                    button.register-button {
                        margin-top: 1rem;
                        padding: 0.6rem;
                        font-size: 12px;
                        font-weight: 500;
                    }
                }
            }

            div.referral-code__wrapper label {
                font-size: 12px;
            }

            div.register-form__footer {
                div.register-form__footer__open-account {
                    span {
                        font-size: 12px !important;
                    }
                }

                button.footer__google-btn {
                    font-size: 12px;
                    padding: 0.8rem;
                    font-weight: 500;

                    svg {
                        width: 15px !important;
                        height: 15px !important;
                    }
                }

                div.footer__login-redirect {

                    a,
                    span {
                        font-size: 12px;
                    }
                }
            }
        }
    }
}


@media screen and (min-width: 1280px) and (max-width: 1439px) {
    div.register-container {
        width: 920px;
        margin-top: 7% !important;

        div.register__form__wrapper {
            padding: 0px 20px !important;

            div.form-title {
                gap: 0.25rem !important;

                h1 {
                    font-size: 1.5em;
                    width: max-content;
                }

                p {
                    font-size: 12px;
                    width: max-content;
                }
            }

            div.form-tabs {
                margin-bottom: 1rem !important;
                gap: 1rem;

                button {
                    font-size: 14px;
                }
            }

            div.form-field-wrapper {
                form {
                    input {
                        height: 45px;
                        font-size: 14px;

                        &::placeholder {
                            font-size: 13px;
                        }
                    }

                    button.password-toggle-btn {
                        right: 5px;
                        bottom: 12px;
                    }

                    div.form-field-wrapper__forget-password {
                        a {
                            font-size: 14px;
                        }
                    }

                    button.register-button {
                        margin-top: 1rem;
                        padding: 0.8rem;
                        font-size: 14px;
                        font-weight: 500;
                    }
                }
            }

            div.register-form__footer {
                div.register-form__footer__open-account {
                    span {
                        font-size: 13px !important;
                    }
                }

                button.footer__google-btn {
                    font-size: 14px;
                    padding: 0.8rem;
                    font-weight: 500;

                    svg {
                        width: 15px !important;
                        height: 15px !important;
                    }
                }

                div.footer__login-redirect {

                    a,
                    span {
                        font-size: 13px;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1024px) and (max-width: 1279px) {
    div.register-container {
        width: 650px;
        margin-top: 12% !important;

        div.register__form__wrapper {
            padding: 0px 20px !important;

            div.form-title {
                gap: 0.25rem !important;

                h1 {
                    font-size: 1.25em;
                    width: max-content;
                }

                p {
                    font-size: 12px;
                    width: max-content;
                }
            }

            div.form-tabs {
                margin-bottom: 1rem !important;
                gap: 1rem;

                button {
                    font-size: 12px;
                }
            }

            div.form-field-wrapper {
                form {
                    input {
                        height: 40px;
                        font-size: 14px;

                        &::placeholder {
                            font-size: 12px;
                        }
                    }

                    div.register-form__footer__open-account {
                        a {
                            font-size: 12px;
                        }
                    }

                    button.register-button {
                        margin-top: 1rem;
                        padding: 0.5rem;
                        font-size: 14px;
                    }
                }
            }

            div.register-form__footer {
                div.register-form__footer__open-account {
                    span {
                        font-size: 12px !important;
                    }
                }

                button.footer__google-btn {
                    font-size: 12px;
                    padding: 0.5rem;
                    font-weight: 500;

                    svg {
                        width: 15px !important;
                        height: 15px !important;
                    }
                }

                div.footer__login-redirect {

                    a,
                    span {
                        font-size: 12px;
                    }
                }
            }
        }
    }
}


@media screen and (min-width: 991px) and (max-width: 1280px) {
    div.register-container {
        width: 800px;
    }
}

@media screen and (min-width: 800px) and (max-width: 1280px) {
    div.register-container {
        width: 850px;

        div.register__form__wrapper {
            padding: 0px 20px !important;

            div.form-title {
                gap: 0.5rem !important;

                h1 {
                    font-size: 1.35em;
                    width: max-content;
                }

                p {
                    font-size: 14px;
                    width: max-content;
                }
            }

            div.form-tabs {
                margin-bottom: 1rem !important;
                gap: 1rem;

                button {
                    font-size: 12px;
                }
            }

            div.form-field-wrapper {
                form {
                    input {
                        height: 40px;
                        font-size: 14px;

                        &::placeholder {
                            font-size: 12px;
                        }
                    }

                    div.register-form__footer__open-account {
                        a {
                            font-size: 12px;
                        }
                    }

                    button.register-button {
                        margin-top: 1rem;
                        padding: 0.5rem;
                        font-size: 14px;
                    }
                }
            }

            div.register-form__footer {
                div.register-form__footer__open-account {
                    span {
                        font-size: 12px !important;
                    }
                }

                button.footer__google-btn {
                    font-size: 12px;
                    padding: 0.5rem;
                    font-weight: 500;

                    svg {
                        width: 15px !important;
                        height: 15px !important;
                    }
                }

                div.footer__login-redirect {

                    a,
                    span {
                        font-size: 12px;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    div.register-container {
        width: 600px;
    }
}

@media screen and (min-width: 640px) and (max-width: 767px) {
    div.register-container {
        width: 100%;
        box-shadow: unset !important;

        div.register__form__wrapper {
            padding-left: 0px;
            padding-right: 0px;
        }
    }
}

@media screen and (max-width: 639px) {
    div.register-container {
        width: 100%;
        box-shadow: unset !important;

        div.register__form__wrapper {
            padding-left: 0px;
            padding-right: 0px;
        }
    }
}

div.register-container {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 15px;

    input:hover,
    input:not([value='']),
    input:active {
        border-color: #EFB81C !important;
    }
}


#checkbox-tick {
    display: inline-block;
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

#checkbox-tick input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

#checkbox-tick input:checked~.checkbox__check-mark {
    background-color: #EFB81C;
}

#checkbox-tick input:checked~.checkbox__check-mark:after {
    opacity: 1;
}

#checkbox-tick:hover input~.checkbox__check-mark {
    background-color: #eee;
}

#checkbox-tick:hover input:checked~.checkbox__check-mark {
    background-color: #EFB81C;
}

#checkbox-tick .checkbox__check-mark {
    position: absolute;
    top: -10px;
    left: 2px;
    height: 20px;
    width: 20px;
    background-color: #eee;
    transition: background-color 0.25s ease;
    border-radius: 4px;
}

#checkbox-tick .checkbox__check-mark:after {
    content: "";
    position: absolute;
    left: 8px;
    top: 4px;
    width: 5px;
    height: 10px;
    border: solid #333;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    opacity: 0;
    transition: opacity 0.25s ease;
}



// React Tel Input in the MOBILE Login Section
.react-tel-input .form-control {
    padding-left: 10px !important;
    width: 100%;
    background: unset !important;
    border-radius: 0.375rem !important;
    height: unset !important;
    width: 100% !important;
    outline: none !important;
}
.react-tel-input{
    display: flex;
    width: 100%;
    flex-direction: row-reverse;
    gap: 20px;
}

.react-tel-input .country-list {
    width: 245px !important;
}

.flag-dropdown {
    width: 30%;
}

.react-tel-input .flag-dropdown {
    position: unset !important;
    padding: 0px 5px !important;
    background: transparent !important;
    border-radius: 0.375rem !important;
}

.react-tel-input .country-list {
    left: 0;
    top: 48px;
}

.react-tel-input .selected-flag {
    outline: none;
    // position: relative;
    /* width: 38px; */
    height: 100%;
    padding: 0 0 0 8px;
    border-radius: 3px 0 0 3px;
}

.react-tel-input .selected-flag .arrow {
    position: relative;
    top: 50%;
    margin-top: -2px;
    left: 47px;
    width: 7px;
    height: 0px;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 7px solid #555;
}
.search-emoji {
        display: none;
}

.search-box {
    background-color: #f1f1f1;
    border: 0px;
    width: 90%;
}

.react-tel-input .country-list .search-box { 
    border: 0px;
}
.react-tel-input .country-list .country {
    padding: 7px 9px;
    display: flex;
}
.react-tel-input .flag-dropdown.open .selected-flag {
    background: #fff;
    width: 100%;
    border-radius: 3px 0 0 0;
}