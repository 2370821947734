section.user-dashboard__user-preview {
    box-shadow: rgba(33, 35, 38, 0.1) 0px 6px 6px -10px;

    div.user-dashboard__user-account__status:hover {
        cursor: default;
        transition: all 300ms ease-in-out;
        transform: translateY(-1px);
        box-shadow: rgba(17, 17, 26, 0.1) 0px 2px 0px;
    }
}


span {
    font-size: 12px;
}

a:hover {
    text-decoration: none !important;
}

select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding: 5px 8px !important;
    min-height: 40px;
    border: 0.5px solid #15141510;
    border-radius: 10px;

    background-image:
        linear-gradient(45deg, transparent 50%, gray 50%),
        linear-gradient(135deg, gray 50%, transparent 50%);
    background-position:
        calc(100% - 14px) calc(1em + 5px),
        calc(100% - 8px) calc(1em + 5px);
    background-size:
        5px 6px,
        6px 5px;
    background-repeat: no-repeat;

    &:focus,
    &:active {
        outline: none;
    }
}


// Custom checkbox styles

input.buycex__toggle-check[type="checkbox"] {
    position: relative;
    width: 35px;
    height: 18px;
    appearance: none;
    -webkit-appearance: none;
    background: #F4F4F4;
    outline: none;
    border-radius: 20px;
    border: 1px solid #15141510;
    box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
    transition: 0.5s;
}

input.buycex__toggle-check:checked[type="checkbox"] {
    background: #EFB81C;
}

input.buycex__toggle-check[type="checkbox"]:before {
    content: '';
    position: absolute;
    width: 14px;
    height: 14px;
    border-radius: 20px;
    top: 1px;
    left: 1px;
    background: #F4F4F4;
    transform: scale(1.1);
    box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px;
    transition: .5s;
}

input.buycex__toggle-check:checked[type="checkbox"]:before {
    left: 18px;
}

//   Custom checkbox style ends

div.user-section__wrapper-container {
    background: linear-gradient(to bottom, #F4F4F4, #FFFFFF);
}


// Tooltip in the user dashboard (Identity Verification) status
#identity-verification-tooltip {
    font-size: 12px !important;
}

#pnl-info-tooltip {
    font-size: 11px !important;
    width: 250px !important;
    color: #F4F4F4 !important;
    background: #151415 !important;
    z-index: 999 !important;
}

#account-overview-verification-status,
#disabled-vip-level-tooltip {
    font-size: 11px !important;
    color: #FFFFFF;
    background-color: #151415;
    width: 150px !important;
    z-index: 999;
}

// UserDashboard User Crypto details table
table.user-dashboard__assets__crypto-details-table {
    tbody {
        tr.crypto-details__table__row:hover {
            box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 10px -10px, rgba(0, 0, 0, 0.45) 0px -3px 10px -10px;
            transition: all ease-out 300ms;
        }
    }
}



div.account-overview__login-status-table__wrapper {
    min-height: 25dvh;

    table.login-status-table {
        overflow: hidden;
        border-radius: 10px;

        thead.login-status-table__header {
            tr {
                transition: all ease-out 300ms;
                border-bottom: 1px solid #15141510;
                font-size: 12px;

                th {
                    font-size: 12px;
                    color: #15141580;
                    font-weight: 500;
                    text-align: left;
                    padding: 10px 10px;
                    cursor: default;

                    &:last-child {
                        text-align: right;
                    }
                }
            }
        }

        tbody.login-status-table__body {
            tr {
                transition: all ease-out 300ms;

                th {
                    font-size: 12px;
                    font-weight: normal;
                }

                &:hover {
                    background: #F4F4F4;
                }

                td {
                    font-size: 12px;
                    color: #151415;
                    text-align: left;
                    padding: 15px 10px;
                    cursor: default;

                    &:last-child {
                        text-align: right;
                    }
                }
            }
        }
    }
}

// Security Settings
#g2fa-tooltip {
    font-size: 12px !important;
    max-width: 250px;
    background: #F4F4F4;
    border-radius: 10px;
    opacity: 1 !important;
    color: #151415;
}


// User Notification
.notification__item__name.unread-notification {
    &::after {
        content: '';
        position: absolute;
        top: 39%;
        right: -12px;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background: #EFB81C;
    }

}

// User > Notifications > Status Filter
.user-notification__status-filter-wrapper {
    .user-notification__status-filter {
        .filter__toggler__btn {
            transition: all ease-out 300ms;

            &:hover {
                background: #EFB81C;
                color: #151415;
            }

            &.active-status-filter {
                background: #EFB81C;
                color: #151415;
            }
        }
    }
}


// User > Notifications > Category Filter
.user-notification__category-filter__dropdown {

    select {
        min-height: unset !important;
        min-width: 50px !important;
        max-width: 145px !important;
        padding: 5px 8px !important;
        padding-right: 30px !important;

        background-image:
            linear-gradient(45deg, transparent 50%, gray 50%),
            linear-gradient(135deg, gray 50%, transparent 50%);
        background-position:
            calc(100% - 17px) calc(1em + 0.5px),
            calc(100% - 12px) calc(1em + 0.5px);
        background-size:
            4px 5px,
            5px 4px;
        background-repeat: no-repeat;

        &:focus,
        &:active {
            outline: none;
        }
    }
}


.rdrDefinedRangesWrapper,
.rdrDateDisplay {
    display: none !important;
}

.rdrDateRangePickerWrapper {
    position: absolute !important;
    right: 0;
    top: 43px;

    .rdrCalendarWrapper {
        box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
        border-radius: 10px;

        .rdrMonthAndYearWrapper {
            height: 45px !important;
            padding: unset !important;
        }

        .rdrMonth {
            width: 18rem !important;

            .rdrMonthName {
                padding: 8px 12px;
            }

            .rdrDayToday .rdrDayNumber span:after {
                background: #EFB81C !important;
            }
        }

        .rdrStaticRangeLabel {
            font-size: 12px;
        }

        .rdrInputRange {

            .rdrInputRangeInput {
                width: 20px;
                height: 20px;
                font-size: 12px;
            }

            span {
                font-size: 12px;
                line-height: unset !important;
            }
        }

        .rdrMonthAndYearPickers {
            select {
                padding: 8px !important;
                padding-right: 30px !important;
                max-width: 90px !important;
                min-height: unset !important;
                border: 1px solid #15141510;
            }
        }

        span {
            font-size: 12px;
        }
    }
}