// components/market/overview/MarketOverviewStyle.scss
// All Styles for Market Overview Table


div.market-data-wrapper {

    button,
    a,
    * {
        transition: none !important;
    }

    div.market-data-table-container {
        div.market-data-table-wrapper {
            // background: white;
            border: 1px solid #e5e7eb;
            border-radius: 10px;
            margin-top: 10px;

            table.market-data-table {
                thead.market-data-table__header {
                    tr.market-data-table__header-row {
                        background-color: transparent;
                        border-bottom: 1px solid #F4F4F4;

                        th.market-data-table__header-row__heading {
                            padding: 15px 15px;
                            text-align: right;
                            width: calc(100% / 6);

                            &:last-child {
                                text-align: right;
                            }

                            h4.heading-title {
                                font-family: 'IBM Plex Sans', sans-serif !important;
                                font-size: 14px;
                                font-weight: 500;
                                line-height: 20px;
                                color: #15141580;
                            }
                        }
                    }
                }

                tbody.market-data-table__body {
                    tr.market-data-table__body-row {
                        align-items: center;

                        &:hover {
                            background: linear-gradient(to top, #15141505, #EFB81C00, #EFB81C00, #15141505);
                        }

                        td.market-data-table__body-row__data {
                            width: 100%;
                            padding: 15px 15px;
                            text-align: end;
                            justify-content: end;

                            &:last-child {
                                text-align: right;
                            }
                        }
                    }
                }
            }
        }
    }
}