@tailwind base;
@tailwind components;
@tailwind utilities;


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* transition: all 300ms ease-in-out; */
}

.rc-slider-captcha-button {
  border-radius: 50% !important;
  width: 50px !important;
  height: 50px !important;
  margin-top: -3px !important;
}

.rc-slider-captcha-control {
  border-radius: 50px !important;
  background-color: #DFE1E3 !important;
}

.rc-slider-captcha-control-indicator {
  border-radius: 50px !important;
}

.rc-slider-captcha-control-tips {
  color: gray !important;
  font-size: 17px !important;
  padding-left: 36px !important;
  overflow: hidden !important;
}

.rc-slider-captcha-icon{
  font-size: 22px !important;
  margin-top: -2px !important;
}

.font {
  font-size: 22px !important;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background: #a0a0a0;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

body * {
  font-family: "IBM Plex Sans", sans-serif;
  /* font-size: 16px; */

  scrollbar-width: thin;
  scrollbar-color: #a0a0a0 #f5f5f5;
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
.title,
.sub-title,
.heading,
.sub-heading {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  line-height: 1.6;
}

.container {
  width: 100%;
  padding-right: 32px;
  padding-left: 32px;
  margin-right: auto;
  margin-left: auto;
}

@media screen and (max-width: 639px) {
  .container {
    max-width: 100% !important;
  }
}

@media screen and (min-width: 640px) and (max-width: 767px) {
  .container {
    max-width: 640px !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .container {
    max-width: 768px !important;
  }
}

@media screen and (min-width: 1400px) {
  .container {
    max-width: 1250px !important;
  }
}

@media screen and (min-width: 1920px) {
  .container {
    max-width: 1280px !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .container {
    max-width: 100% !important;
  }
}

@media screen and (min-width: 1400px) and (max-width: 1919px) {
  .container {
    max-width: 1400px !important;
  }
}

/* @media screen and (min-width: 1920px) {
  .container {
    max-width: 100% !important;
  }
} */

/* ? Custom fonts */

/* IBM Plex Sans */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: local('IBM Plex Sans Thin'), url('../fonts/IBMPlexSans/IBMPlexSans-Thin.woff') format('woff');
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: local('IBM Plex Sans ExtraLight'), url('../fonts/IBMPlexSans/IBMPlexSans-ExtraLight.woff') format('woff');
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('IBM Plex Sans Light'), url('../fonts/IBMPlexSans/IBMPlexSans-Light.woff') format('woff');
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('IBM Plex Sans Regular'), url('../fonts/IBMPlexSans/IBMPlexSans-Regular.woff') format('woff');
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('IBM Plex Sans Medium'), url('../fonts/IBMPlexSans/IBMPlexSans-Medium.woff') format('woff');
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('IBM Plex Sans SemiBold'), url('../fonts/IBMPlexSans/IBMPlexSans-SemiBold.woff') format('woff');
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('IBM Plex Sans Bold'), url('../fonts/IBMPlexSans/IBMPlexSans-Bold.woff') format('woff');
}

/* IBM Plex Sans Italic */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: local('IBM Plex Sans Thin Italic'), url('../fonts/IBMPlexSans/IBMPlexSans-ThinItalic.woff') format('woff');
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: local('IBM Plex Sans ExtraLight Italic'), url('../fonts/IBMPlexSans/IBMPlexSans-ExtraLightItalic.woff') format('woff');
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: local('IBM Plex Sans Light Italic'), url('../fonts/IBMPlexSans/IBMPlexSans-LightItalic.woff') format('woff');
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('IBM Plex Sans Italic'), url('../fonts/IBMPlexSans/IBMPlexSans-Italic.woff') format('woff');
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: local('IBM Plex Sans Medium Italic'), url('../fonts/IBMPlexSans/IBMPlexSans-MediumItalic.woff') format('woff');
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: local('IBM Plex Sans SemiBold Italic'), url('../fonts/IBMPlexSans/IBMPlexSans-SemiBoldItalic.woff') format('woff');
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local('IBM Plex Sans Bold Italic'), url('../fonts/IBMPlexSans/IBMPlexSans-BoldItalic.woff') format('woff');
}

/* Raleway */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: local('Raleway Thin'), url('../fonts/Raleway/Raleway-Thin.woff') format('woff');
}

@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: local('Raleway ExtraLight'), url('../fonts/Raleway/Raleway-ExtraLight.woff') format('woff');
}

@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('Raleway Light'), url('../fonts/Raleway/Raleway-Light.woff') format('woff');
}

@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Raleway Regular'), url('../fonts/Raleway/Raleway-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Raleway Medium'), url('../fonts/Raleway/Raleway-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('Raleway SemiBold'), url('../fonts/Raleway/Raleway-SemiBold.woff') format('woff');
}

@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Raleway Bold'), url('../fonts/Raleway/Raleway-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: local('Raleway ExtraBold'), url('../fonts/Raleway/Raleway-ExtraBold.woff') format('woff');
}

@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local('Raleway Black'), url('../fonts/Raleway/Raleway-Black.woff') format('woff');
}

/* Raleway Italic */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: local('Raleway Thin Italic'), url('../fonts/Raleway/Raleway-ThinItalic.woff') format('woff');
}

@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: local('Raleway ExtraLight Italic'), url('../fonts/Raleway/Raleway-ExtraLightItalic.woff') format('woff');
}

@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: local('Raleway Light Italic'), url('../fonts/Raleway/Raleway-LightItalic.woff') format('woff');
}

@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('Raleway Italic'), url('../fonts/Raleway/Raleway-Italic.woff') format('woff');
}

@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: local('Raleway Medium Italic'), url('../fonts/Raleway/Raleway-MediumItalic.woff') format('woff');
}

@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: local('Raleway SemiBold Italic'), url('../fonts/Raleway/Raleway-SemiBoldItalic.woff') format('woff');
}

@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local('Raleway Bold Italic'), url('../fonts/Raleway/Raleway-BoldItalic.woff') format('woff');
}

@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: local('Raleway ExtraBold Italic'), url('../fonts/Raleway/Raleway-ExtraBoldItalic.woff') format('woff');
}

@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: local('Raleway Black Italic'), url('../fonts/Raleway/Raleway-BlackItalic.woff') format('woff');
}