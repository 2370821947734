@media screen and (max-width: 991px) {
    section.customer-support-section {
        div.container {
            div.inner-wrapper {
                div.header {
                    h4 {
                        font-size: 22px;
                    }

                    p {
                        width: 100%;
                        max-width: 100%;
                        font-size: 1em;
                    }
                }

                div.body {
                    flex-direction: column;
                    gap: 10px;

                    div.body_card-wrapper {
                        width: 100%;
                        max-width: 100%;

                        span {
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }
}