.gridentpre-bg{
  background-image: linear-gradient(150deg, rgba(244, 80, 28, .1), #1e2023 100px, rgba(244, 80, 28, .2) 1600px);
}
.default-font {
    font-family: sans-serif !important;
  }
  
  .item {
    align-items: center;
    color: black;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    height: 40px;
    justify-content: center;
    width: 40px;
  }
  

  
  .premarketcontainer .active {
    border:2px solid yellow;
    border-radius: 40px;
    color: yellow;
  }
  
  
  
 
  
  .pagination {
    align-items: center;
    background-color: #0fbcf9;
    display: flex;
    flex-direction: row;
    height: 60px;
    justify-content: center;
    list-style-type: none;
    position: relative;
    width: 1000px;
  }
  
  .pagination-page {
    font-weight: 700;
  }
  .premarketcontainer{
    padding-inline: 60px;
    
  }
 .premarketcontainer-header{
   
   position: relative;
   background-image: linear-gradient(to bottom, #FFFFFF, rgba(244, 244, 244, 0.062745098), rgba(239, 184, 28, 0.062745098)), url("assets/img/global/buycex-card-bg.svg");
   background-repeat: no-repeat;
   background-size: 160% 70%;
   background-position: bottom;  
 } 


 .premarket-slider-card .swiper-button-prev, .premarket-slider-card .swiper-button-next{
display: none!important;
 }
 .premarket-slider-card swiper-pagination swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal{
   bottom:10px!important;
 }

  /* .active {
  border: none!important;
  border-radius: 0px!important;
} */
 @media screen and (max-width:1024px){
  .premarketcontainer{
    padding-inline: 10px;
  }
 }
  
  
  
 
  
 
  
 
  
 
  
 