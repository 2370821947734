button {
    transition: all ease-in-out 300ms !important;
}


.footer__coin-slider {
    ::-webkit-scrollbar {
        display: none;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;

    overflow: hidden;
    white-space: nowrap;
    position: relative;

    .footer__coin-slider-item {
        padding: 0px 2px;
    }
}

select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding: 4px 8px !important;
    min-height: unset !important;
    border: 0.5px solid #15141510;
    border-radius: 10px;

    background-image:
        linear-gradient(45deg, transparent 50%, gray 50%),
        linear-gradient(135deg, gray 50%, transparent 50%);
    background-position:
        calc(100% - 14px) calc(1em + 0px),
        calc(100% - 8px) calc(1em + 0px);
    background-size:
        4px 5px,
        6px 5px;
    background-repeat: no-repeat;

    &:focus,
    &:active {
        outline: none;
    }
}


// For input type:Number
/* Force the spinner arrows to always show */
input[type="number"] {
    -moz-appearance: textfield;
    /* For Firefox */
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    opacity: 1;
    /* Make the arrows always visible */
    display: inline-block;
    /* Ensure they are displayed */
}


// For input type:Range 
input[type=range] {
    height: 22px;
    -webkit-appearance: none;
    appearance: none;
    margin: 10px 0;
    width: 100%;
}

input[type=range]:focus {
    outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 6px;
    cursor: pointer;
    animation-duration: 0.2s;
    background: #F4F4F4;
    border-radius: 10px;
    border: 1px solid #ddd;
}

input[type=range]::-webkit-slider-thumb {
    height: 15px;
    width: 15px;
    border: 3px solid #EFB81C;
    border-radius: 25px;
    background: #FFF;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -4.5px;
}

input[type=range]:focus::-webkit-slider-runnable-track {
    background: #F4F4F4;
}

input[type=range]::-moz-range-track {
    width: 100%;
    height: 6px;
    cursor: pointer;
    animation-duration: 0.2s;
    background: #F4F4F4;
    border-radius: 10px;
    border: 1px solid #ddd;
}

input[type=range]::-moz-range-thumb {
    height: 15px;
    width: 15px;
    border: 3px solid #EFB81C;
    border-radius: 25px;
    background: #FFF;
    cursor: pointer;
}

input[type=range]::-ms-track {
    width: 100%;
    height: 6px;
    cursor: pointer;
    animation-duration: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
}

input[type=range]::-ms-fill-lower {
    background: #F4F4F4;
    border-radius: 20px;
}

input[type=range]::-ms-fill-upper {
    background: #F4F4F4;

    border-radius: 20px;
}

input[type=range]::-ms-thumb {
    margin-top: 1px;
    height: 15px;
    width: 15px;
    border: 3px solid #EFB81C;
    border-radius: 25px;
    background: #FFF;
    cursor: pointer;
}

input[type=range]:focus::-ms-fill-lower {
    background: #F4F4F4;
}

input[type=range]:focus::-ms-fill-upper {
    background: #F4F4F4;
}


#user-guide-tooltip {
    font-size: 12px;
    color: #151415;
    background: #bbb;
    padding: 5px;
}




/* For webkit browsers like Chrome, Safari */
.order-table-container::-webkit-scrollbar {
    width: 0;
}

/* For Firefox */
.order-table-container {
    scrollbar-width: none;
}

/* For Internet Explorer, Edge */
.order-table-container {
    -ms-overflow-style: none;
}

#add-fund-tooltip {
    width: 180px;
    padding: 5px;
    font-size: 12px;
    color: #151415;
    background: #bbb;
}

#leverage-tooltip {
    width: max-content;
    padding: 5px;
    font-size: 12px;
    color: #151415;
    background: #bbb;
}
.dark .ant-modal-content{
    background-color: #101014;
}
.dark .ant-modal-header{
    background-color: #101014;
    color: #ffffff;
    .ant-modal-title{
        color: #ffffff;
    }
}
.dark .ant-modal-close{
    // background-color: white;
    color: #ffffff;
}
.dark .ant-modal-close:hover{
    // background-color: white;
    background-color: #ffffff;
    color: black;}


// use for the sliders in tradearea 
    .slider-container {
        width: 100%;
        // max-width: 400px;
        position: relative;
    }
    
    .slider-track {
        position: relative;
        height: 4px;
        background-color: #ddd;
        border-radius: 2px;
    }
    
    .slider-dot {
        position: absolute;
        top: -3px;
        height: 10px;
        width: 10px;
        border: 2px solid #ddd;
        background-color: #fff;
        border-radius: 50%;
        transform: translateX(-50%);
        cursor: pointer;
        transition: background-color 0.3s ease;
    }
    .dark .slider-dot{
        background-color: #000;
    }
    .slider-dot.active {
        border: 4px solid yellow;
        height: 14px;
        width: 14px;
        top: -5px;
    }
    
    .slider-value {
        position: absolute;
        top: 10px;
        font-size: 12px;
        color:#15141580;
        transform: translateX(-50%);
    }
    .dark .slider-value{
        color: #71777A;
    }
  