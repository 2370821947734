.slick-dots li.slick-active button:before {
    opacity: .75;
    color: #EFB81C;
}
.slick-dots li button:before {
    font-size: 12px;
}

.flip-text {
    display: inline-block;
    position: relative;
    opacity: 0;
    transform: translateY(100%);
    transition: all 0.6s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.flip-enter {
    opacity: 1;
    transform: translateY(0%);
}

.flip-exit {
    opacity: 0;
    transform: translateY(-100%);
}

.sliding-phrase {
    display: inline-block;
    position: relative;
    transition: transform 0.4s ease-in-out;
}

.slide-more {
    transform: translateX(-6px);
}

.slide-less {
    transform: translateX(-3px);
}
